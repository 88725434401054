export const prioritizeStreams = (streams, priorityStreams) => {
  const validPriorityStreams = priorityStreams.filter((stream) => streams.includes(stream));
  const filteredStreams = streams.filter((streams) => !validPriorityStreams.includes(streams));
  return [...validPriorityStreams, ...filteredStreams];
};

export const timeInterval = [
  {
    label: '1 second',
    value: 1,
  },
  {
    label: '5 seconds',
    value: 5,
  },
  {
    label: '10 seconds',
    value: 10,
  },
  {
    label: '15 seconds',
    value: 15,
  },
  {
    label: '30 seconds',
    value: 30,
  },
  {
    label: '1 minute',
    value: 60,
  },
];
