import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Heading, FormControl, HStack, Button, useDisclosure, Text, Badge, useToast, IconButton, Spacer, Tooltip } from '@chakra-ui/react';
import { SortableList } from '../common/sortable/SortableList';
import { arrayMove } from '@dnd-kit/sortable';
import { Select } from 'chakra-react-select';
import { chakraStyles } from '@/features/common/select/styles.js';
import Layout from '@/features/common/Layout';
import { selectDivisionsList } from '../enum/enumSlice';
import Conditions from './Conditions';
import {
  useGetSchemaQuery,
  useUpdateMarginRulesMutation,
  useGetMarginRulesMutation,
  useLazyGetAllRuleVersionsQuery,
  useLazyGetRuleVersionQuery,
  useRollbackMarginRulesMutation,
} from '@/app/services/nucleus';
import AlertComponent from '../common/AlertComponent';
import { useRole } from '@/hooks/useRole';
import ModalComponent from '../common/ModalComponent';
import RulesErrorListComponent from './RulesErrorListComponent';
import { isArrayValue } from '@/utils/helpers';
import { selectMarginRuleTemplate } from './marginSlice';
import { useNotification } from '@/hooks/useNotification';
import LoaderOverlay from '@/features/common/LoaderOverlay';
import { BiCollapse, BiExpandAlt } from 'react-icons/bi';
const DEBUG = false;

/** INFO: This component is responsible for managing the margin rules for a given division.
 - It allows the user to add, remove, and modify rules and conditions.
 - It also allows the user to rollback to a previous version of the rules.
 - The user can only modify the rules if they are not in read-only mode. (controlled by feature flag)
 - The user can only select a version if there are versions available
 - The user can only rollback if there are versions available.
 - The user can only save if there are rules available and they are not in read-only mode.
 - The user can only cancel if there are unsaved changes
 - Rules are fetched from nuclues and transformed into a format more suitable for form validation
 - rule and condition updates are handled by a callback (Condition) which updates the 'rule' state in MarginRules
 - saving rules to nuclues xforms rules back to nucleus format and sends them to nucleus
 - The rules are stored in a stringified format to track modifications
 - Rules are NOT fetched when the user saves. The UI relies on component state to maintain changes that were made.
 - rules are only fetched from the server when the division is changed.
 **/

const MarginRules = () => {
  useRole('admin');
  const toast = useToast();
  const confirmDeleteModal = useDisclosure();
  const confirmCancelModal = useDisclosure();
  const confirmRollbackModal = useDisclosure();
  const divisions = useSelector(selectDivisionsList);
  const ruleTemplate = useSelector(selectMarginRuleTemplate);
  const [division, setDivision] = useState(null);
  const [rules, setRules] = useState([]);
  const [activeDeleteRule, setActiveDeleteRule] = useState(null);
  const [activeDeleteRuleName, setActiveDeleteRuleName] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [ruleString, setRuleString] = useState('');
  const [versions, setVersions] = useState([]);
  const [activeVersion, setActiveVersion] = useState(null);

  const { data: jobOptions, isLoading: isSchemaLoading, isError: isSchemaError, error: schemaError } = useGetSchemaQuery({ schema: 'JobFromDB' });

  const [getAllVersions, { isLoading: isVersionsLoading, isError: isVersionsError, error: versionsError }] = useLazyGetAllRuleVersionsQuery();
  const [getVersion, { isLoading: isVersionLoading, isError: isVersionError, error: versionError }] = useLazyGetRuleVersionQuery();

  const [getRules, { isLoading: isMarginRulesLoading, isError: isMarginRulesError, error: marginRulesError, reset: getMarginRulesReset }] =
    useGetMarginRulesMutation();

  const [
    rollback,
    { isLoading: isRollbackLoading, isSuccess: isRollbackSuccess, isError: isRollbackError, error: rollbackError, reset: rollbackReset },
  ] = useRollbackMarginRulesMutation();

  const [
    updateMarginRules,
    {
      isLoading: isUpdateMarginRulesLoading,
      isSuccess: isUpdateMarginRulesSuccess,
      isError: isUpdateMarginRulesError,
      error: updateMarginRulesErrorMsg,
      reset: updateMarginRulesReset,
    },
  ] = useUpdateMarginRulesMutation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  // make sure page is fully visible when loading
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (division?.label || (division?.label && (isUpdateMarginRulesSuccess || isRollbackSuccess))) {
      getAllVersions({ division: division?.label }, false)
        .unwrap()
        .then((payload) => {
          if (DEBUG) {
            console.log('///////////// VERSIONS //////////////');
            console.log(payload);
          }
          setVersions(payload);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [division, isUpdateMarginRulesSuccess, isRollbackSuccess]);

  useNotification(isUpdateMarginRulesSuccess, null, isUpdateMarginRulesSuccess, 'success', 'Margin Rules Saved');
  useNotification(isRollbackSuccess, null, isRollbackSuccess, 'success', 'Rollback Successful');

  useNotification(
    isUpdateMarginRulesError,
    scrollToTop,
    isUpdateMarginRulesError,
    'error',
    'Margin Rules update failed. Please resolve issues marked below',
  );

  useEffect(() => {
    if (DEBUG) {
      if (jobOptions) {
        console.log('///////////// FIELD OPTIONS //////////////');
        console.log(jobOptions);
      }
    }
  }, [DEBUG, jobOptions]);

  // we want to track modification status based on changes to rules
  useEffect(() => {
    if (isArrayValue(rules) && ruleString !== '') {
      const rulesWithoutOpen = rules.map(({ open, ...rest }) => rest);
      const parseRuleString = JSON.parse(ruleString);
      const ruleStringWithoutOpen = parseRuleString.map(({ open, ...rest }) => rest);
      if (JSON.stringify(rulesWithoutOpen) !== JSON.stringify(ruleStringWithoutOpen)) {
        setIsModified(true);
      }
    } else {
      setIsModified(false);
    }
  }, [rules]);

  const stageDeleteRule = (e, item) => {
    setActiveDeleteRule(item.id);
    setActiveDeleteRuleName(item?.name?.value);
    confirmDeleteModal.onOpen();
  };

  const handleDeleteRule = (e, id) => {
    const newRules = rules.map((r) => {
      if (r?.id === id) {
        return {
          ...r,
          deleted: true,
        };
      } else {
        return r;
      }
    });
    setRules(newRules);
    setActiveDeleteRule(null);
    setActiveDeleteRuleName(null);
    confirmDeleteModal.onClose();
  };

  const handleAddMarginRule = () => {
    const maxValue =
      rules && rules.length > 0
        ? Math.max.apply(
            null,
            rules.map((o) => o.id),
          )
        : 0;
    const newRule = { ...ruleTemplate };
    newRule.id = maxValue + 1;
    getMarginRulesReset();
    setRules([newRule, ...rules]);
  };

  const handleCancel = () => {
    handleDivisionChange(null, { action: 'cancel' });
    confirmCancelModal.onClose();
  };

  const handleStageCancel = () => {
    confirmCancelModal.onOpen();
  };

  const confirmRollback = () => {
    updateMarginRulesReset();
    rollback({ division: division?.label, version: activeVersion?.value })
      .unwrap()
      .then((payload) => {
        if (DEBUG) {
          console.log('///////////// ROLLBACK //////////////');
          console.log(payload);
        }
        setIsReadOnly(false);
        setActiveVersion(null);
        setRules(payload);
        setRuleString(JSON.stringify(payload));
        confirmRollbackModal.onClose();
      })
      .catch((error) => {
        console.log(error);
        setRules([]);
      });
  };

  const handleRollback = (e) => {
    e.preventDefault();
    confirmRollbackModal.onOpen();
  };

  const handleSaveMarginRules = (e) => {
    e.preventDefault();
    updateMarginRulesReset();
    // remove the id property from the rules
    const activeRules = rules.filter((r) => !r.deleted);
    const payloadRules = activeRules.map(({ id, conditions, ...rest }) => {
      const cond = isArrayValue(conditions) ? conditions.map(({ id, ...c }) => c) : [];
      const { margin, minimum_margin, min_pay_regular, pay_travel, should_calculate_pay, name, expires_on, priority } = rest;
      let timestamp = null;
      const expires = expires_on?.value ? new Date(expires_on.value) : null;
      if (expires) {
        const year = expires.getFullYear();
        const month = expires.getMonth() + 1;
        const day = expires.getDate();
        timestamp = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
      }
      return {
        expires_on: timestamp,
        conditions: cond,
        name: name?.value || null,
        margin: margin.value,
        minimum_margin: minimum_margin.value,
        min_pay_regular: min_pay_regular.value,
        pay_travel: pay_travel.value,
        should_calculate_pay: should_calculate_pay.value,
        priority: priority.value?.label,
      };
    });
    const payload = {
      division: division?.label,
      rules: payloadRules,
    };
    updateMarginRules({ rules: payload })
      .unwrap()
      .then((payload) => {
        if (DEBUG) {
          console.log('///////////// MARGIN RULES SAVED //////////////');
          console.log(payload);
        }
        setRules(payload);
        setRuleString(JSON.stringify(payload));
      })
      .catch((error) => {
        console.log(error);
      });
    setIsModified(false);
  };

  const handleVersionChange = (val, { action }) => {
    if (action === 'select-option' && val?.label) {
      setActiveVersion(val);
      getVersion({ division: division?.label, version: val?.value })
        .unwrap()
        .then((payload) => {
          if (DEBUG) {
            console.log('///////////// MARGIN RULES //////////////');
            console.log(payload);
          }
          setRules(payload);
          setRuleString(JSON.stringify(payload));
          setIsReadOnly(true);
        })
        .catch((error) => {
          console.log(error);
          setRules([]);
        });
    } else if (action === 'clear') {
      handleDivisionChange(division, { action: 'select-option' });
      setActiveVersion(null);
      setIsReadOnly(false);
    }
  };

  const handleDivisionChange = (val, { action }) => {
    if (isModified && action !== 'cancel') {
      toast({
        title: 'You have unsaved changes. Please save or cancel.',
        status: 'warning',
        variant: 'subtle',
        position: 'top',
        isClosable: true,
      });
    } else {
      if (action === 'select-option') {
        if (val?.label !== '') {
          getRules({ division: val?.label })
            .unwrap()
            .then((payload) => {
              if (DEBUG) {
                console.log('///////////// MARGIN RULES //////////////');
                console.log(payload);
              }
              setRules(payload);
              setRuleString(JSON.stringify(payload));
            })
            .catch((error) => {
              console.log(error);
              setRules([]);
            });
        }
      } else if (action === 'clear' || action === 'cancel') {
        setRules([]);
        getMarginRulesReset();
      }
      setDivision(val);
      setIsModified(false);
    }
  };

  const handleSortUp = useCallback(
    (e, idProp) => {
      e.stopPropagation();
      const activeIndex = rules.findIndex(({ id }) => id === idProp);
      const overIndex = Math.max(0, activeIndex - 1);
      const newItems = arrayMove(rules, activeIndex, overIndex);
      setRules(newItems);
    },
    [rules],
  );

  const handleSortDown = useCallback(
    (e, idProp) => {
      e.stopPropagation();
      const activeIndex = rules.findIndex(({ id }) => id === idProp);
      const overIndex = Math.min(rules.length, activeIndex + 1);
      const newItems = arrayMove(rules, activeIndex, overIndex);
      setRules(newItems);
    },
    [rules],
  );

  const handleExpirationUpdate = (val, id) => {
    setIsInvalid(false);
    const newRules = rules.map((rule) => {
      const label = rule['expires_on']?.label;
      if (rule.id === id) {
        const dateVal = val === 'clear' ? undefined : new Date(val);
        return { ...rule, 'expires_on': { value: dateVal, valid: true, error: '', label } };
      } else {
        return rule;
      }
    });
    setRules(newRules);
  };

  // set rule value from input fields
  const handleRuleUpdate = useCallback(
    (e, id, fieldName) => {
      setIsInvalid(false);
      const newRules = rules.map((rule) => {
        if (rule.id === id) {
          if (fieldName === 'open') {
            return { ...rule, open: !rule.open };
          }
          let valid = true;
          let error = '';
          const label = rule[fieldName]?.label;
          let val = fieldName === 'should_calculate_pay' ? e.target.checked : e.target.value;

          if (fieldName === 'priority') {
            return {
              ...rule,
              priority: {
                ...rule.priority,
                value: { label: val, value: val?.split(' ')[0].toLowerCase() },
              },
            };
          }

          // validate value is not empty
          if (val === '' || val === null || typeof val === 'undefined') {
            valid = false;
            error = `${label} is required`;
          }
          if (fieldName === 'pay_travel' || fieldName === 'min_pay_regular') {
            if (String(val).split('.')[1]?.length > 2) {
              val = val.toFixed(2);
            }
          }
          // validate positive number
          if (valid && val < 0) {
            valid = false;
            error = `${label} must be a positive number`;
          }
          if (error !== '') {
            setIsInvalid(true);
          }
          return { ...rule, [fieldName]: { value: val, valid, error, label } };
        } else {
          return rule;
        }
      });
      setRules(newRules);
    },
    [rules],
  );

  const expandCollapseRules = (expand) => {
    const newRules = rules.map((rule) => {
      return { ...rule, open: expand };
    });
    setRules(newRules);
  };
  const handleExpandRules = () => {
    expandCollapseRules(true);
  };
  const handleCollapseRules = () => {
    expandCollapseRules(false);
  };

  // NOTE: translate conditons back to what nucleus expects
  // i.e. - { field_name: '', operator: 0, value: ''}
  const handleConditionsUpdate = useCallback(
    (id, data) => {
      const newConditions = [];
      data.forEach((row, index) => {
        let name = null;
        if (row?.field?.value && row.field.value.length > 0) {
          name = row.field.value.map((f) => f.value).join('.');
        }
        newConditions[index] = {
          id: row?.id,
          field_name: name,
          operator: row?.operator?.value,
          value: row?.input?.value,
        };
      });
      const newRules = rules.map((rule) => {
        if (rule.id === id) {
          return {
            ...rule,
            conditions: newConditions,
          };
        } else {
          return rule;
        }
      });
      setRules(newRules);
    },
    [rules],
  );

  return (
    <Box data-testid="pact-margin-rules">
      <Layout pageTitle={'Pact'}>
        <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
          Margin Rules {isReadOnly && <Badge colorScheme="yellow">Read Only</Badge>}
        </Heading>
        <Box mt="6" p={0}>
          <HStack spacing={4} justifyContent={'space-between'}>
            <FormControl id="division" maxW={'50%'} py={6}>
              <Select
                isDisabled={isReadOnly}
                aria-label="division"
                chakraStyles={chakraStyles}
                focusBorderColor="brand.700"
                options={divisions}
                onChange={handleDivisionChange}
                isClearable
                isSearchable
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 200 }),
                }}
                value={division}
                placeholder="Select a division"
              />
            </FormControl>
            <HStack>
              <Button isDisabled={!division || isReadOnly} variant="purple" onClick={handleAddMarginRule}>
                Add Rule
              </Button>
              <Button isDisabled={!division || isInvalid || isReadOnly} variant="purple" onClick={handleSaveMarginRules}>
                Save
              </Button>
              <Button isDisabled={(rules && rules.length === 0) || !isModified || isInvalid} variant="solid" onClick={handleStageCancel}>
                Cancel
              </Button>
            </HStack>
          </HStack>
          <HStack spacing={0} mb={5} justifyContent="space-between">
            {division && isArrayValue(versions) ? (
              <HStack mb={5} justifyContent="start" width="50%">
                <FormControl id="versions">
                  <Select
                    aria-label="versions"
                    chakraStyles={chakraStyles}
                    focusBorderColor="brand.700"
                    options={versions}
                    onChange={handleVersionChange}
                    isClearable
                    isSearchable
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 200 }),
                    }}
                    value={activeVersion}
                    placeholder="Select a version"
                  />
                </FormControl>
                <HStack>
                  <Button isDisabled={!activeVersion || !isReadOnly} variant="purple" onClick={handleRollback}>
                    Rollback
                  </Button>
                </HStack>
              </HStack>
            ) : (
              <Spacer />
            )}
            {division && (
              <HStack mb={5} justifyContent="end" width="50%">
                <Tooltip label="Expand Rules" openDelay={500}>
                  <IconButton variant="outline" onClick={handleExpandRules} aria-label="Expand all rules" icon={<BiExpandAlt />} />
                </Tooltip>
                <Tooltip label="Collapse Rules" openDelay={500}>
                  <IconButton variant="outline" onClick={handleCollapseRules} aria-label="Collapse all rules" icon={<BiCollapse />} />
                </Tooltip>
              </HStack>
            )}
          </HStack>
          {isUpdateMarginRulesError && (
            <Box spacing={3} maxHeight={400} overflowY={'auto'}>
              <Heading as="h5" size="md" fontWeight="bold" color="brand.900" mb={3}>
                Errors
              </Heading>
              <RulesErrorListComponent errors={updateMarginRulesErrorMsg?.data?.detail} mb={5} />
            </Box>
          )}
          <LoaderOverlay
            loading={
              isUpdateMarginRulesLoading || isMarginRulesLoading || isSchemaLoading || isVersionsLoading || isVersionLoading || isRollbackLoading
            }
          />
          {isSchemaError && <AlertComponent status="error" title="Error fetching job schema" description={schemaError?.data?.detail} />}
          {isVersionsError && (
            <AlertComponent status="error" title="Error fetching margin rules version history" description={versionsError?.data?.detail} />
          )}
          {isRollbackError && <AlertComponent status="error" title="Rollback error" description={rollbackError?.data?.detail} />}
          {isVersionError && (
            <AlertComponent
              status="error"
              title={`Error fetching margin rules for ${activeVersion?.label}`}
              description={versionError?.data?.detail}
            />
          )}
          {isMarginRulesError ? (
            <AlertComponent status="error" title="Error fetching margin rules" description={marginRulesError?.data?.detail} />
          ) : (
            <Box mt={6}>
              <SortableList
                items={rules}
                onChange={setRules}
                isReadOnly={isReadOnly}
                renderItem={(item, isFirst, isLast) => (
                  <Conditions
                    item={item}
                    readOnly={isReadOnly}
                    DragHandle={SortableList.DragHandle}
                    first={isFirst}
                    last={isLast}
                    onSortUp={handleSortUp}
                    onSortDown={handleSortDown}
                    onDeleteRule={stageDeleteRule}
                    onConditionsUpdate={handleConditionsUpdate}
                    onRuleUpdate={handleRuleUpdate}
                    onExpirationUpdate={handleExpirationUpdate}
                    jobOptions={jobOptions}
                  />
                )}
              />
            </Box>
          )}
        </Box>
        <ModalComponent
          size="md"
          title="Confirm Delete"
          primaryText="Yes"
          secondaryText="No"
          handleConfirm={(e) => handleDeleteRule(e, activeDeleteRule)}
          onOpen={confirmDeleteModal.onOpen}
          isOpen={confirmDeleteModal.isOpen}
          onClose={confirmDeleteModal.onClose}
          isError={false}
        >
          <Box>
            <Text>
              Are you sure you want to remove <b>{activeDeleteRuleName ? activeDeleteRuleName : `Margin Rule ${activeDeleteRule}`}</b>
            </Text>
          </Box>
        </ModalComponent>
        <ModalComponent
          size="md"
          title="Confirm Cancel"
          primaryText="Yes"
          secondaryText="No"
          handleConfirm={handleCancel}
          onOpen={confirmCancelModal.onOpen}
          isOpen={confirmCancelModal.isOpen}
          onClose={confirmCancelModal.onClose}
          isError={false}
        >
          <Box>
            <Text>Are you sure you want to discard all changes to {division?.label} Margin Rules</Text>
          </Box>
        </ModalComponent>
        <ModalComponent
          size="md"
          title="Confirm Rollback"
          primaryText="Yes"
          secondaryText="No"
          handleConfirm={confirmRollback}
          onOpen={confirmRollbackModal.onOpen}
          isOpen={confirmRollbackModal.isOpen}
          onClose={confirmRollbackModal.onClose}
          isError={false}
        >
          <Box>
            <Text>Are you sure you want to rollback Margin Rules to {activeVersion?.label}</Text>
          </Box>
        </ModalComponent>
      </Layout>
    </Box>
  );
};

export default MarginRules;
